import styled from 'styled-components';

import directionImg from '../images/map.jpg';

export const Columns = styled.div`
  display: flex;
  flex-flow: column;

  padding-top: 2rem;

  @media only screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4rem;
  }
`;

export const Icon = styled.div`
  color: ${({ theme }): string => theme.primary800};

  display: flex;
  align-items: center;

  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  font-family: ${({ theme }): string => theme.fontHeader};

  color: white;
  margin-top: 1rem;
`;

export const Map = styled.div`
  background-image: url(${directionImg});
  background-size: cover;
  cursor: pointer;

  height: 312px;
  margin-top: 1rem;
`;
