import { Modal, Typography } from 'amphitheatre';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { MdEmail, MdLocationOn } from 'react-icons/md';
import Obfuscate from 'react-obfuscate';

import {
  BackgroundWrapper,
  BLCircle,
  BRBigCircle,
  BRSmallCircle,
  TLCircle,
} from '../components/Background';
import Layout from '../components/Layout';
import directionImg from '../images/map.jpg';
import { Columns, Icon, Info, Map } from '../pages-styles/contactus.styled';
import * as Styled from '../pages-styles/shared.styled';

const { Header, Paragraph } = Typography;

const ContactUs: React.FunctionComponent = () => {
  const [visible, setVisible] = useState(false);

  const contactEmail = 'info@wolfpackspacehub.com';
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - Contact Us</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Columns>
          <div className="col-1">
            <Header className="page-header" style={{ marginTop: 0 }}>
              CONTACT US
            </Header>
            <Paragraph>
              Send us an email, we endeavour to answer all inquiries within 24
              hours on business days.
            </Paragraph>
            <Paragraph>
              The Wolfpack HQ is accessible by public transport with Redfern
              Station, a stroll away and bus stops right around the corner.
            </Paragraph>
            <br />
            <Info>
              <Icon>
                <MdLocationOn />
              </Icon>
              24-30 Wellington Street, Waterloo 2017
            </Info>
            <Info>
              <Icon>
                <MdEmail />
              </Icon>
              <Obfuscate
                email={contactEmail}
                style={{ color: 'white', textDecoration: 'none' }}
              />
            </Info>
          </div>
          <div>
            <Map onClick={(): void => setVisible(true)} />
          </div>
        </Columns>
      </Styled.Container>
      <Modal
        cancel={(): void => setVisible(false)}
        footer={<div />}
        style={{
          minWidth: '80%',
          maxWidth: '80%',
          width: '80%',
          top: '50%',
        }}
        visible={visible}
      >
        <img
          src={directionImg}
          style={{ width: '100%' }}
          alt="Map locating Wolfpack"
        />
      </Modal>
    </Layout>
  );
};

export default ContactUs;
